import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Gear Up Garage
			</title>
			<meta name={"description"} content={"Кожна миля - новий спогад"} />
			<meta property={"og:title"} content={"Про нас | Gear Up Garage"} />
			<meta property={"og:description"} content={"Кожна миля - новий спогад"} />
			<meta property={"og:image"} content={"https://go.movixenpro.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://go.movixenpro.com/img/go.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://go.movixenpro.com/img/go.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://go.movixenpro.com/img/go.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://go.movixenpro.com/img/go.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://go.movixenpro.com/img/go.png"} />
			<meta name={"msapplication-TileImage"} content={"https://go.movixenpro.com/img/go.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://go.movixenpro.com/img/3.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
				Наша історія
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
				Gear Up Garage був заснований на вірі в те, що кожен водій заслуговує на надійне та якісне автомобільне обслуговування. Те, що починалося як невелика майстерня, перетворилося на висококласну станцію технічного обслуговування, відому своєю прихильністю до задоволеності клієнтів та безпеки транспортних засобів. Наш шлях продиктований пристрастю до автомобільної досконалості та інновацій, що сприяє створенню спільноти, де до кожного автомобіля ставляться з максимальною увагою та професіоналізмом.
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-4">
			<Text font="--headline2" margin="0px 0px 40px 0px" color="--darkL2" text-align="center">
			Кожна миля - новий спогад
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Познайомтеся з нашими механіками
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Серцем Gear Up Garage є наші досвідчені механіки. Завдяки всебічній підготовці та вмінню вирішувати складні проблеми, наша команда об'єднує різноманітні навички для обслуговування всіх типів транспортних засобів. Від старовинної класики до новітніх моделей, наші механіки - не просто постачальники послуг, а автолюбителі, які ставляться до кожного автомобіля, як до власного.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Що нас відрізняє?
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Передові технології: Ми випереджаємо час, використовуючи новітні автомобільні технології для ефективної діагностики та усунення проблем.
							<br/><br/>
Індивідуальні рішення: Незалежно від того, чи потрібна вам планова перевірка, аварійний ремонт або підвищення продуктивності, ми адаптуємо наші послуги до ваших конкретних потреб.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Екологічно чисті методи: Ми віримо в сталий розвиток і використовуємо екологічно чисті методи в наших послугах, щоб захистити планету.
							<br/><br/>
Довічна гарантія на обслуговування: Ми відповідально ставимося до своєї роботи і гарантуємо якість, яка залишається надовго після того, як ви виїдете з нашого гаража.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="16px 16px 16px 16px"
				lg-order="-2"
				lg-width="100%"
				width="100%"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					overflow-x="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						position="absolute"
						display="block"
						width="100%"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://go.movixenpro.com/img/4.jpg"
						object-fit="cover"
						top="auto"
						right={0}
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				width="40%"
				align-items="flex-start"
			>
				<Box
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 60% 0px"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						width="100%"
						right={0}
						min-height="100%"
						top="auto"
						left={0}
						bottom="0px"
						src="https://go.movixenpro.com/img/5.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				flex-direction="row"
				flex-wrap="wrap"
				width="60%"
				lg-width="100%"
				lg-order="-1"
			>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="100%"
					sm-margin="0px 0px 0px 0px"
					width="100%"
					flex-direction="column"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="8px 16px 8px 16px"
				>
					<Text
						lg-text-align="center"
						sm-font="--headline3"
						margin="20px 0px 0px 0px"
						color="--darkL2"
						font="--headline3"
						md-text-align="left"
					>
						Створіть свою подорож з нами
					</Text>
				</Box>
				<Box
					align-items="flex-start"
					width="100%"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					flex-direction="column"
					padding="16px 16px 16px 16px"
					display="flex"
					sm-margin="0px 0px 0px 0px"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--grey"
						font="--base"
						lg-text-align="center"
						sm-margin="0px 0px 30px 0px"
						md-text-align="left"
					>
						У Gear Up Garage ми віримо, що догляд за вашим автомобілем має бути подорожжю, а не просто послугою. Ми запрошуємо вас стати частиною нашої історії, де ми відзначаємо унікальну історію кожного автомобіля та допомагаємо створити його майбутнє. Довіртеся нам не просто технічне обслуговування, а партнерство у підтримці вашого автомобіля в ідеальній формі.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});